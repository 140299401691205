<template>

  <!-- {{user}} -->
  <div class="col-lg-9 col-md-9 pb-5" style="position: relative;">
    <div
      v-if="user.user_type === 2"
      class="cart_icon d-flex align-items-center"
      @click="$router.push(`/auth/home?user_account=${user.account}`)"
    >
      <i class="ri-store-line me-1" style="display: block; font-size: 22px;"></i>
      <div>商城</div>
    </div>
    <el-tabs v-model="activeName" type="card" style="position: relative;">
      <el-tab-pane :label="$t('label.personalInformation')" name="first">
        <div class="col-lg-12">
          <div class="iq-edit-list-data">
            <div class="tab-content">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('label.personalInformation')}}</h4>
                </template>
                <template v-slot:body>
                  <div class="row align-items-center justify-content-center position-relative">
                    <div class="mb-100px position-relative">
                      <!-- {{ user }} -->
                      <!-- <img :src="user.profile_banner" alt="profile_banner" class="rounded img-fluid"> -->
                      <div class="rounded img-fluid img_wrap" :style="`background-size: cover;background-position: center center;background-image: url(${user.profile_banner});`">
                      </div>
                      <div class="b-image position-absolute top-1 right-1">
                        <vue-core-image-upload @click="cLoder('banner')" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploadingBanner" :headers="{'authorization':token}"
                          @imagechanged="imagechanged({'type':'user_banner'})" :data=" { 'type': 'user_banner' }" :max-file-size="commomData.uploadOption.maxFileSize" :url="commomData.uploadOption.url"
                          :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text="." :isXhr="false">
                          <i v-if="commomData.btnLoader && isEditBanner" class="fa fa-spinner fa-spin"></i>
                          <i v-else class="ri-pencil-line upload-button text-white"></i>
                          
                          <input class="file-upload" type="file" accept="image/*" />
                        </vue-core-image-upload>
                      </div>
                    </div>
                    <div class="d-inline-block w-50 text-center position-absolute bottom-0">
                      <div class="profile-img-edit">
                        <img class="user-avatar-130x130 img-fluid rounded-circle me-3" :src="user.avatar" alt="profile-pic" />
                        <div class="p-image">
                          <vue-core-image-upload @click="cLoder('avatar')" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{'authorization':token}"
                            @imagechanged="imagechanged({'type':'avatar'})" :data=" { 'type': 'avatar' }" :max-file-size="commomData.uploadOption.maxFileSize" :url="commomData.uploadOption.url"
                            :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                            <i v-if="commomData.btnLoader && isEditAvatar" class="fa fa-spinner fa-spin"></i>
                            <i v-else class="ri-pencil-line upload-button text-white"></i>
                            
                            <input class="file-upload" type="file" accept="image/*" />
                          </vue-core-image-upload>
                        </div>

                      </div>
                    </div>
                  </div>
                  <form>
                    <div class="row align-items-center">
                      <div class="form-group col-sm-6">
                        <label for="fname" class="form-label">{{$t('label.account')}}</label>
                        <div class="input-group">
                          <!-- <div class="input-group-prepend">
                            <div class="input-group-text">@u</div>
                          </div> -->
                          <input type="text" v-model="userForm.account" class="form-control" id="account" name="account" :placeholder="$t('label.accountPlaceholder')"
                            :class="{ 'is-invalid': v$.userForm.account.$dirty && !v$.userForm.account.$pending && v$.userForm.account.$invalid }" disabled />
                          <span v-if="v$.userForm.account.$dirty && !v$.userForm.account.$pending && v$.userForm.account.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
                        </div>
                      </div>

                      <div class="form-group col-sm-6">
                        <label for="user_name" class="form-label">{{$t('label.userName')}}</label>
                        <input type="text" v-model="userForm.user_name" class="form-control" id="user_name" name="user_name" :placeholder="$t('label.userNamePlaceholder')"
                          :class="{ 'is-invalid':  v$.userForm.user_name.$dirty && !v$.userForm.user_name.$pending && v$.userForm.user_name.$invalid }" />
                        <span v-if="v$.userForm.user_name.$dirty && !v$.userForm.user_name.$pending && v$.userForm.user_name.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
                      </div>

                      <div class="form-group col-sm-6">
                        <label for="fname" class="form-label">Facebook</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <el-image style="width: 20px; height: 25px" :src="require('@/assets/images/social/fb.svg')"></el-image>
                            </div>
                          </div>
                          <input type="text" v-model="userForm.facebook" class="form-control" id="facebook" name="facebook" :placeholder="$t('label.linkPlaceholder')" />
                        </div>
                      </div>
                      <div class="form-group col-sm-6">
                        <label for="fname" class="form-label">IG</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <el-image style="width: 20px; height: 25px" :src="require('@/assets/images/social/ig.svg')"></el-image>
                            </div>
                          </div>
                          <input type="text" v-model="userForm.ig" class="form-control" id="ig" name="ig" :placeholder="$t('label.linkPlaceholder')" />
                        </div>
                      </div>
                      <div class="form-group col-sm-6">
                        <label for="fname" class="form-label">Twitter</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <el-image style="width: 20px; height: 25px" :src="require('@/assets/images/social/twitter.svg')"></el-image>
                            </div>
                          </div>
                          <input type="text" v-model="userForm.twitter" class="form-control" id="twitter" name="twitter" :placeholder="$t('label.linkPlaceholder')" />
                        </div>
                      </div>
                      <div class="form-group col-sm-6">
                        <label for="fname" class="form-label">TikTok</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <el-image style="width: 20px; height: 25px" :src="require('@/assets/images/social/tiktok.svg')"></el-image>
                            </div>
                          </div>
                          <input type="text" v-model="userForm.tik_tok" class="form-control" id="tik_tok" name="tik_tok" :placeholder="$t('label.linkPlaceholder')" />
                        </div>
                      </div>
                      <div class="form-group col-sm-12">
                        <label for="fname" class="form-label">Youtube</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <el-image style="width: 20px; height: 25px" :src="require('@/assets/images/social/youtube.svg')"></el-image>
                            </div>
                          </div>
                          <input type="text" v-model="userForm.yt" class="form-control" id="yt" name="yt" :placeholder="$t('label.linkPlaceholder')" />
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <label class="form-label">{{$t('label.personalProfile')}}</label>
                        <textarea class="form-control" v-model="userForm.personal_profile" name="personal_profile" rows="5" style="line-height: 22px" :placeholder="$t('label.personalProfilePlaceholder')">
                        </textarea>
                      </div>
                      <!-- 喜好內容 -->
                      <PreferTagList :userForm="userForm" :addTags="addTags" />
                    </div>
                    <div class="pt-3 pb-5 col-sm-12 d-flex justify-content-end">
                      <button @click="onUserSubmit" type="button" class="btn btn-primary me-2">
                        <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                        {{ $t('button.submit')}}
                      </button>
                    </div>
                  </form>
                </template>
              </iq-card>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('label.changePassword')" name="second">
        <div class="col-lg-12">
          <div class="iq-edit-list-data">
            <div class="tab-content">

              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('label.changePassword')}}</h4>
                </template>
                <template v-slot:body>
                  <form>
                    <div class="form-group">
                      <label for="cpass" class="form-label">{{$t('label.currentPassword')}}</label>
                      <a href="/auth/recoverpassword" target="_blank" class="float-end">{{$t('label.resetPassword')}}</a>
                      <input type="password" v-model="passwordForm.password" class="form-control mb-0" id="password" name="password" :placeholder="$t('label.currentPasswordPlaceholder')"
                        :class="{ 'is-invalid': v$.passwordForm.password.$error }" @blur="v$.passwordForm.$touch()" />
                      <span v-if="v$.passwordForm.password.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                    </div>
                    <div class="form-group">
                      <label for="npass" class="form-label">{{$t('label.newPassword')}}</label>
                      <input type="password" v-model="passwordForm.new_password" class="form-control mb-0" id="new_password" name="new_password" :placeholder="$t('label.newPasswordPlaceholder')"
                        :class="{ 'is-invalid': v$.passwordForm.new_password.$error }" @blur="v$.passwordForm.$touch()" />
                      <span v-if="v$.passwordForm.new_password.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                    </div>
                    <div class="form-group">
                      <label for="vpass" class="form-label">{{$t('label.verifyPassword')}}</label>
                      <input type="password" v-model="passwordForm.re_type_password" class="form-control mb-0" id="re_type_password" name="re_type_password" :placeholder="$t('label.rePasswordPlaceholder')"
                        :class="{ 'is-invalid': v$.passwordForm.re_type_password.$error }" @blur="v$.passwordForm.$touch()" />
                      <span v-if="v$.passwordForm.re_type_password.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button @click="onPasswordSubmit" type="button" class="btn btn-primary me-2">
                        <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                        {{ $t('button.submit')}}
                      </button>
                    </div>

                  </form>
                </template>
              </iq-card>

            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div v-if="bannerPreviewShow" class="modal_wrap">
      <div class="modal_inner">
        <div style="width: 100%;height: 80vh">
          <VueCropper
            ref="cropper"
            :img="bannerPreview"
            :outputSize="option.outputSize"
            :outputType="option.outputType"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="option.fixedBox"
            :canScale="option.canScale"
            :centerBox="option.centerBox"
          />
        </div>
        <div style="display: flex; justify-content: flex-end;" class="mt-2">
          <button @click="bannerPreviewShow = false" type="button" class="btn btn-primary me-2">{{ $t('label.cancel') }}</button>
          <button @click="confirmCropper" type="button" class="btn btn-primary me-2">
            <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
            {{ $t('button.confirm') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { socialvue } from '../../config/pluginInit'
import { mapGetters } from 'vuex'
import { ref } from 'vue'

import { required, minLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { editProfile, editPassword, upload } from '@/api/user'
import { commomData } from '@/utils/commonVmodel'
import VueCoreImageUpload from 'vue-core-image-upload'
import PreferTagList from './Components/PreferTagList'
import { VueCropper } from "vue-cropper";
import 'vue-cropper/dist/index.css';

export default {
  name: 'ProfileEdit',
  components: {
    'vue-core-image-upload': VueCoreImageUpload,
    PreferTagList,
    VueCropper
  },
  mounted() {
    this.userForm.user_name = this.user.user_name
    this.userForm.account = this.user.account.replace('@u', '')
    this.userForm.personal_profile = this.user.personal_profile
    this.userForm.yt = this.user.yt
    this.userForm.tik_tok = this.user.tik_tok
    this.userForm.twitter = this.user.twitter
    this.userForm.ig = this.user.ig
    this.userForm.facebook = this.user.facebook
    this.userForm.tags = this.user.tags

    if (this.isMobileDevice) {
      this.option = {
        ...this.option,
        autoCropWidth: 400,
        autoCropHeight: 144
      }
    }
  },
  setup() {
    const activeName = ref('first')

    const v$ = useVuelidate()
    return { v$, activeName }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState'
    }),
    isMobileDevice() {
      let mobileDevices = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
      for (let i = 0; i < mobileDevices.length; i++) {
          if (navigator.userAgent.match(mobileDevices[i])) {
            //console.log("isMobileDevice: match " + mobileDevices[i]);
            return true
          }
      }
      return false
    }
  },
  validations() {
    return {
      userForm: {
        user_name: { required },
        account: { required, minLength: minLength(1) }
      },
      passwordForm: {
        password: { required, minLength: minLength(6) },
        new_password: { required, minLength: minLength(6) },
        re_type_password: { required, minLength: minLength(6) }
      }
    }
  },
  data() {
    return {
      commomData: commomData(),
      userForm: {
        user_name: '',
        account: '',
        personal_profile: '',
        tags: []
      },
      passwordForm: {
        password: '',
        new_password: '',
        re_type_password: ''
      },
      avatar: '',
      tagList_1: [],
      tagList_2: [],
      bannerPreview: '',
      bannerPreviewShow: false,
      option: {
        outputSize: 1,
        outputType: "png", // 產生圖片的格式
        autoCrop: true, // 是否要有截圖框
        autoCropWidth: 1200, // 截圖框寬
        autoCropHeight: 442, //截圖框高
        fixedBox: true,
        canScale: false,
        centerBox: true
      },
      isEditAvatar: false,
      isEditBanner: false
    }
  },
  methods: {
    imageuploaded(res) {
      console.log(res)
      this.commomData.btnLoader = false
      this.$store.dispatch('User/fetchUserAction')
    },
    imageuploading(res) {
      console.log(res)
      this.commomData.btnLoader = true
    },
    imageuploadingBanner(res) {
      // this.commomData.btnLoader = true
      this.getBase64(res)
    },
    getBase64(file) {
      const reader = new FileReader();
      const vm = this;

      reader.readAsDataURL(file)
      reader.onload = function () {
        vm.bannerPreview = reader.result
        vm.bannerPreviewShow = true
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },
    imagechanged(res) {
      console.log(res)
    },
    cLoder(val) {
      if (val === 'avatar') {
        this.isEditAvatar= true
      } else {
        this.isEditBanner = true;
      }
      this.commomData.btnLoader = true
      setTimeout(() => {
        this.isEditAvatar= false;
        this.isEditBanner = false;
        this.commomData.btnLoader = false
      }, 3000)
    },
    onUserSubmit() {
      this.v$.userForm.$touch()
      if (this.v$.userForm.$error) {
        return
      }
      this.commomData.btnLoader = true
      editProfile(this.userForm)
        .then(res => {
          this.$toast.show(res.message)
          this.$store.dispatch('User/fetchUserAction')
          this.commomData.btnLoader = false
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    onPasswordSubmit() {
      this.v$.passwordForm.$touch()
      if (this.v$.passwordForm.$error) {
        return
      }
      this.commomData.btnLoader = true
      editPassword(this.passwordForm)
        .then(res => {
          this.$toast.show(res.message)
          this.$store.dispatch('User/fetchUserAction')
          this.commomData.btnLoader = false
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    // previewImage: function (event) {
    //   const input = event.target
    //   console.log(event)
    //   if (input.files && input.files[0]) {
    //     const reader = new FileReader()
    //     reader.onload = (e) => {
    //       this.user.profile_image = e.target.result
    //     }
    //     reader.readAsDataURL(input.files[0])
    //   }
    // },
    changePassword: function() {},
    addTags(tagName) {
      if (this.userForm.tags.includes(tagName)) {
        this.userForm.tags = this.userForm.tags.filter(item => item !== tagName)
        return
      }
      this.userForm.tags.push(tagName)
    },
    confirmCropper() {
      this.commomData.btnLoader = true
      this.$refs.cropper.getCropData((data) => {
        const file = this.dataURLtoFile(data, 'a.png');
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 'user_banner');
        upload(formData)
          .then(res => {
            this.$toast.show(res.message)
            this.$store.dispatch('User/fetchUserAction')
            this.commomData.btnLoader = false
            this.bannerPreview = ''
            this.bannerPreviewShow = false
          })
          .catch(err => {
            this.commomData.btnLoader = false
            console.log(err)
            this.$swal.mixin().fire({
              icon: 'error',
              text: err.response.data.message
            })
          })
      });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    }
  }
}
</script>
<style>
.profile-img-edit {
  position: relative;
  display: inline-block;
  height: 150px;
  width: 150px;
}
.profile-pic {
  max-width: 100%;
  display: block;
}
.p-image {
  position: absolute;
  top: auto;
  right: 8px;
  bottom: 20px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: #50b5ff;
  color: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}
.b-image {
  position: absolute;
  top: auto;
  right: 1rem;
  bottom: 0.5rem;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: #50b5ff;
  color: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}
.file-upload {
  display: none;
}
.upload-button {
  font-size: 1.5em;
}
.bottom-75px {
  padding-top: 75px;
}
.mb-100px {
  margin-bottom: 75px;
}

.modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 2000;
}
.modal_inner {
  width: 90%;
  margin: 20px auto;
  background: #FFF;
  border-radius: 10px;
  padding: 10px;
}
.modal_inner img {
  max-width: 100%;
  max-height: 90vh;
}

.img_wrap {
  height: 200px;
}
@media screen and (max-width: 400px) {
  .img_wrap {
    height: 144px;
  }
}

.cart_icon {
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 999;
  cursor: pointer;
  /* width: 45px;
  height: 45px; */
}
</style>
